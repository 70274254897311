import React from 'react'
import { type RouteObject } from 'react-router-dom'

import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonIcon from '@mui/icons-material/Person'

import ForgotPassword from 'pages/ForgotPassword'
import Layout from 'components/Layout/Layout'
import Dashboard from 'pages/Dashboard'
import Account from 'pages/Account'
import SignIn from 'pages/SignIn'
import Logs from 'pages/Logs'

import UserForm from 'pages/Users/Form'
import Users from 'pages/Users'

import PrizeEditForm from 'pages/PrizesGroups/EditForm/PrizeEditForm'
import PrizeAddForm from 'pages/PrizesGroups/AddForm/PrizeAddForm'
import ChangeUserDataForm from 'pages/ChangeUserDataForm'
import Prizes from 'pages/PrizesGroups/Prizes'
import PrizesGroups from 'pages/PrizesGroups'

import ApplicationDetails from 'pages/Applications/Details/ApplicationDetails'
import ApplicationAddForm from 'pages/Applications/Form/ApplicationAddForm'
import ApplicationEditForm from 'pages/Applications/Form/ApplicationEditForm'
import Applications from 'pages/Applications'

import GlobalBarcodesForm from 'pages/Barcodes/GlobalBarcodesForm'
import BarcodesForm from 'pages/Barcodes/Form/BarcodesForm'
import GlobalBarcodes from 'pages/Barcodes/GlobalBarcodes'

import ImportPrizes from 'pages/ImportPrizes/ImportPrizes'

import { handleLogout } from 'utils/handleLogout'

import type { Subjects } from './ability'

export const routerOptions: RouteObject[] = [
  {
    path: '/',
    element: <SignIn />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/dashboard',
    element: (
      <Layout>
        <Dashboard />
      </Layout>
    )
  },
  {
    path: '/account',
    element: (
      <Layout>
        <Account />
      </Layout>
    )
  },
  {
    path: '/logs',
    element: (
      <Layout>
        <Logs />
      </Layout>
    )
  },
  {
    path: '/change-account-data/:field',
    element: (
      <Layout>
        <ChangeUserDataForm />
      </Layout>
    )
  },
  {
    path: '/users',
    element: (
      <Layout>
        <Users />
      </Layout>
    )
  },
  {
    path: '/users/edit/:id',
    element: (
      <Layout>
        <UserForm type='edit' />
      </Layout>
    )
  },
  {
    path: '/users/add',
    element: (
      <Layout>
        <UserForm type='add' />
      </Layout>
    )
  },
  {
    path: '/prizes-groups',
    element: (
      <Layout>
        <PrizesGroups />
      </Layout>
    )
  },
  {
    path: '/prizes',
    element: (
      <Layout>
        <Prizes />
      </Layout>
    )
  },
  {
    path: '/prizes/add',
    element: (
      <Layout>
        <PrizeAddForm />
      </Layout>
    )
  },
  {
    path: '/prizes/edit/:id',
    element: (
      <Layout>
        <PrizeEditForm />
      </Layout>
    )
  },
  {
    path: '/barcodes',
    element: (
      <Layout>
        <GlobalBarcodes />
      </Layout>
    )
  },
  {
    path: '/barcodes/add',
    element: (
      <Layout>
        <BarcodesForm />
      </Layout>
    )
  },
  {
    path: '/global-barcodes/add',
    element: (
      <Layout>
        <GlobalBarcodesForm />
      </Layout>
    )
  },
  {
    path: '/applications',
    element: (
      <Layout>
        <Applications />
      </Layout>
    )
  },
  {
    path: '/applications/:id',
    element: (
      <Layout>
        <ApplicationDetails />
      </Layout>
    )
  },
  {
    path: '/applications/add',
    element: (
      <Layout>
        <ApplicationAddForm />
      </Layout>
    )
  },
  {
    path: '/applications/edit/:id',
    element: (
      <Layout>
        <ApplicationEditForm />
      </Layout>
    )
  },
  {
    path: '/import-prizes',
    element: (
      <Layout>
        <ImportPrizes />
      </Layout>
    )
  }
]

interface NavItem {
  id: string
  name: string
  href: string
  icon: JSX.Element
  readSubject: Subjects
}

interface NavSection {
  id: string
  items: NavItem[]
}

export const navitems: NavSection[] = [
  {
    id: '0001',
    items: [
      { id: '0001-0000', name: 'navigation.dashboard', href: '/dashboard', icon: <HomeOutlinedIcon />, readSubject: 'Dashboard' },
      { id: '0001-0001', name: 'navigation.applications', href: '/applications', icon: <FormatListNumberedOutlinedIcon />, readSubject: 'Applications' },
      { id: '0001-0004', name: 'navigation.prizes', href: '/prizes-groups', icon: <EmojiEventsOutlinedIcon />, readSubject: 'Prizes' },
      { id: '0001-0005', name: 'navigation.barcodes', href: '/barcodes', icon: <QrCode2OutlinedIcon />, readSubject: 'Barcodes' },
      { id: '0001-0006', name: 'navigation.prizeDraw', href: '/import-prizes', icon: <UploadFileOutlinedIcon />, readSubject: 'Import prizes' },
      { id: '0001-0007', name: 'navigation.users', href: '/users', icon: <PeopleAltOutlinedIcon />, readSubject: 'Users' }
    ]
  },
  {
    id: '0002',
    items: [
      { id: '0002-0001', name: 'navigation.logs', href: '/logs', icon: <ArticleOutlinedIcon />, readSubject: 'Logs' }
    ]
  }
]

export const accountItems = [
  {
    id: 'account-menu-0001',
    items: [
      { id: 'account-item-1001', name: 'navigation.account', href: '/account', icon: <PersonIcon /> }
    ]
  },
  {
    id: 'account-menu-0002',
    items: [
      { id: 'account-item-2003', name: 'navigation.logout', href: '/', handler: handleLogout, icon: <LogoutIcon /> }
    ]
  }
]
