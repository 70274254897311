import React from 'react'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AdvancedTable from 'components/AdvancedTable'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import type { Prize } from 'types/Prize'
import { useNavigate } from 'react-router'
import { Can } from 'config/ability'
import { renderCells } from 'utils/renderCells'
import { prizeStatuses } from 'config/prizeStatus'

const Prizes = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('navigation.prizes')}</Typography>
        <Button variant='outlined' sx={{ ml: 'auto', mr: 1 }} onClick={(): void => { navigate('/prizes-groups') }}>{t('common.backListText')}</Button>
        <Can I='create' a='Prizes'>
          <Button variant='contained' onClick={(): void => { navigate('/prizes/add') }}>{t('common.add')}</Button>
        </Can>
      </Box>
      <AdvancedTable<Prize>
        name='prizes'
        endpoint='prizes'
        headings={[
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'empty', name: t('common.name'), field: 'name', width: 160 },
          { type: 'empty', name: t('prize.code'), field: 'code', width: 120 },
          { type: 'options', name: t('common.status'), field: 'status', sort: true, width: 120 },
          { type: 'empty', name: t('common.type'), field: '', width: 120 }
        ]}
        options={{
          status: prizeStatuses
        }}
        renderRow={(row, idx) => (
          <TableRow key={idx}>
            {renderCells([
              { label: 'ID', value: row.id },
              { label: t('common.name'), value: row.prizeGroup?.name ?? 'N/A' },
              { label: t('common.code'), value: row.value !== '' ? row.value : 'N/A' },
              { label: t('common.status'), value: row.status ?? 'N/A' },
              { label: t('common.type'), value: row.prizeGroup?.type ?? 'N/A' }
            ])}
          </TableRow>
        )}
      />
    </>
  )
}

export default Prizes
