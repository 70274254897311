import Paper from '@mui/material/Paper'
import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { UserFormFieldsStep1, UserFormFieldsStep2 } from './ChangeUserDataForm.types'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import Input from 'components/Input'
import { Button, Typography } from '@mui/material'
import * as Yup from 'yup'
import { validations } from './ChangeUserDataForm.config'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const changeData = async (token: string, user: UserFormFieldsStep1): Promise<any> => {
  try {
    return {
      test: 'hello world!',
      user
    }
  } catch (err) {
    console.error(err)
  }
}

const defaultValues: UserFormFieldsStep2 = {
  email: '',
  name: '',
  password: '',
  repeatPassword: ''
}

const defaultPasswordValues: UserFormFieldsStep2 = {
  password: ''
}

const ChangeUserDataForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState<1 | 2>(1)
  const { t } = useTranslation()
  const { token } = useAuthContext()
  const params = useParams<{ field: 'email' | 'name' | 'password' }>()
  const { changeMessage } = useAlert()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: changeUser, data: responseData, reset } = useMutation(async (user: UserFormFieldsStep2) => await changeData(token, user))

  if (params.field === undefined || !(['name', 'email', 'password'].includes(params.field))) {
    navigate('/')
    return <></>
  }

  useEffect(() => {
    if (responseData !== undefined && 'error' in responseData) {
      changeMessage(responseData?.error?.data?.message, 'error', reset)
    }
  }, [responseData])

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string().required(t('forms.required', { field: t('user.password') }) ?? 'Required')
  })
  const validationSchema = Yup.object().shape({
    ...validations(t)[params?.field]
  })

  const { handleSubmit: handleCheckPass, control: passControl } = useForm<UserFormFieldsStep1>({ resolver: yupResolver(passwordValidationSchema), defaultValues: defaultPasswordValues })
  const { handleSubmit, control } = useForm<UserFormFieldsStep2>({ resolver: yupResolver(validationSchema), defaultValues })

  const onHandleSubmitStep1 = (): void => {
    setCurrentStep(2)
  }

  const onHandleSubmitStep2 = (data: UserFormFieldsStep2): void => {
    console.log(data)
    // changeUser(data)
  }

  const renderInputs = useMemo((): JSX.Element => {
    switch (params.field) {
      case 'email': {
        return (
          <Input<UserFormFieldsStep2>
            textInputProps={{ autoFocus: true }}
            name='email'
            label={t('user.email')}
            control={control}
          />
        )
      }
      case 'password': {
        return (
          <>
            <Input<UserFormFieldsStep2>
              textInputProps={{ autoFocus: true, type: 'password' }}
              name='password'
              label={t('user.password')}
              control={control}
            />
            <Input<UserFormFieldsStep2>
              textInputProps={{ type: 'password' }}
              name='repeatPassword'
              label={t('user.repeatPassword')}
              control={control}
            />
          </>
        )
      }
      default: {
        return (
          <Input<UserFormFieldsStep2>
            textInputProps={{ autoFocus: true }}
            name='name'
            label={t('user.name')}
            control={control}
          />
        )
      }
    }
  }, [params])

  const getCurrentStep = useMemo((): JSX.Element => {
    switch (currentStep) {
      case 2: {
        return (
          <form key={`change-${params.field as string}`} onSubmit={handleSubmit(onHandleSubmitStep2)} noValidate>
            {renderInputs}
            <Button
              type='submit'
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              {t('common.change')}
            </Button>
          </form>
        )
      }
      default: {
        return (
          <form key={`change-${params.field as string}-password`} onSubmit={handleCheckPass(onHandleSubmitStep1)} noValidate>
            <Input<UserFormFieldsStep1>
              textInputProps={{ autoFocus: true, type: 'password' }}
              name='password'
              label={t('user.password')}
              control={passControl}
            />
            <Button
              type='submit'
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              {t('common.change')}
            </Button>
          </form>
        )
      }
    }
  }, [currentStep])

  return (
    <Paper sx={{ p: 4 }}>
      <Typography>{t('common.editField', { field: 'hello wolrd' })}</Typography>
      {getCurrentStep}
    </Paper>
  )
}

export default ChangeUserDataForm
