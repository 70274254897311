import React from 'react'
import type { RequestStatusProps } from './RequestStatus.types'
import Loader from 'components/Loader'
import ErrorView from 'components/ErrorView'

const RequestStatus = ({ data, isLoading }: RequestStatusProps): JSX.Element => {
  if (isLoading !== undefined && isLoading) {
    return <Loader />
  }

  if (data !== undefined && 'error' in data && typeof data?.error !== 'string' && data?.error?.status === 401) {
    return <Loader />
  }

  if (data !== undefined && 'error' in data && typeof data?.error !== 'string' && data?.error?.status === 403) {
    return <ErrorView type='account' />
  }

  if (data === undefined || data === null || ('data' in data && data.data instanceof Array && data.data.length === 0)) {
    return <ErrorView type='nodata' />
  }

  return <ErrorView type='danger' />
}

export default RequestStatus
