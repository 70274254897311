import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import React, { type ReactNode, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import RequestStatus from 'components/RequestStatus'
import type { ApiApplicationAdditionalInfo, ApiApplicationDetails } from 'types/Application'
import { CardHeader, Typography, CardActions, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { axiosPatch } from 'connectors/axiosPatch'
import { axiosPost } from 'connectors/axiosPost'
import { StatusTypes, applicationStatuses } from 'pages/Applications/applicationStatuses'
import type { UpdateStatusData } from 'pages/Applications/Applications.types'
import StatusButtons from 'components/StatusButtons'
import IconChip from 'components/IconChip'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import EditIcon from '@mui/icons-material/Edit'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { generateQueryParams, readQueryParams } from 'utils/generateQueryParams'
import Chip from '@mui/material/Chip'
import { History } from './History'
import { DetailsList, Item } from './DetailsList'
import CircularProgress from '@mui/material/CircularProgress'

const ApplicationDetails = (): JSX.Element => {
  const [showHistory, setShowHistory] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading } = useQueryGet<ApiApplicationDetails>({
    queryKey: ['application-details', params.id],
    endpoint: `applications/${params.id ?? ''}`,
    options: { params: searchParams },
    refetchDeps: [searchParams]
  })

  const extraApplicationInfo = useQueryGet<ApiApplicationAdditionalInfo>({
    queryKey: ['application-extra-info', params.id],
    endpoint: `applications/additional-data/${params.id ?? ''}`,
    options: { params: searchParams },
    refetchDeps: [searchParams]
  })

  const searchParamsObject = useMemo(() => readQueryParams(searchParams), [searchParams])

  const { mutate: updateStatusMutate, data: updateStatusResponse, reset } = useMutation(
    async ({ id, newStatusId }: UpdateStatusData) => await axiosPatch<{ affected: number }>(token, refreshToken, `applications/status/${id}`,
      { status: newStatusId }
    ),
    {
      onSuccess: (response, { newStatusId }) => {
        if (data !== undefined && response !== undefined && !('error' in response)) {
          queryClient.setQueryData(
            ['application-details', params.id],
            { ...data, application_status: newStatusId }
          )
        }
      }
    }
  )

  useEffect(() => {
    if (typeof updateStatusResponse !== 'string' && updateStatusResponse !== undefined && 'error' in updateStatusResponse) {
      changeMessage(updateStatusResponse?.error?.data?.message, 'error', () => {
        reset()
      })
    }
    if (typeof updateStatusResponse !== 'string' && updateStatusResponse !== undefined && !('error' in updateStatusResponse) && updateStatusResponse.affected > 0) {
      changeMessage(t('common.success'), 'success', () => {
        reset()
      })
    }
  }, [updateStatusResponse])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: resendVerification, data: verificationResponse, reset: verificationReset, isLoading: isResending } = useMutation(
    async ({ id, email }: { id: string, email: string }) => await axiosPost(token, refreshToken, 'applications/send-verification-email', { id, email })
  )

  if (data === null || data === undefined || 'error' in data) {
    return <RequestStatus data={data} isLoading={isLoading} />
  }

  const renderNav = (): JSX.Element => (<CardHeader
    sx={{ padding: 0, paddingBottom: 2 }}
    title={
      <Button
        type='button'
        variant='outlined'
        onClick={() => {
          navigate(`/applications?${searchParams.toString()}`)
        }}
        startIcon={<ChevronLeft />}
      >
        {t('common.backListText')}
      </Button>
    }
    action={
      <>
        <Button
          variant='outlined'
          disabled={extraApplicationInfo.isLoading || ((extraApplicationInfo.data != null) && !('error' in extraApplicationInfo.data) && !extraApplicationInfo.data.prevApplicationId)}
          onClick={() => {
            if ((extraApplicationInfo.data != null) && !('error' in extraApplicationInfo.data) && extraApplicationInfo.data.prevApplicationId) {
              navigate(`/applications/${extraApplicationInfo.data.prevApplicationId}?${searchParams.toString()}`)
            }
          }}
          startIcon={<NavigateBeforeIcon />}
        >
          {t('common.prev')} {extraApplicationInfo.isLoading ? <CircularProgress size={16} /> : null}
        </Button>
        <Button
          variant='outlined'
          disabled={extraApplicationInfo.isLoading || ((extraApplicationInfo.data != null) && !('error' in extraApplicationInfo.data) && !extraApplicationInfo.data.nextApplicationId)}
          onClick={() => {
            if ((extraApplicationInfo.data != null) && !('error' in extraApplicationInfo.data) && extraApplicationInfo.data.nextApplicationId) {
              navigate(`/applications/${extraApplicationInfo.data.nextApplicationId}?${searchParams.toString()}`)
            }
          }}
          endIcon={<NavigateNextIcon />}
          sx={{ marginLeft: 1 }}
        >
          {t('common.next')} {extraApplicationInfo.isLoading ? <CircularProgress size={16} /> : null}
        </Button>
      </>}
  />)

  const handleDeleteFilter = (name: string, value: string): void => {
    const selectedObject = searchParamsObject[name]

    if (selectedObject instanceof Array) {
      const { [name]: test, ...rest } = searchParamsObject
      setSearchParams(generateQueryParams({ ...rest, [name]: selectedObject.filter(e => e !== value) }))
    } else {
      const { [name]: test, ...rest } = searchParamsObject
      setSearchParams(generateQueryParams(rest))
    }
  }

  const renderFilters = (): ReactNode => {
    const filters: Record<string, Record<string, string>> = {
      status: Object.fromEntries(applicationStatuses.map((name, idx) => [idx, name.value])),
      marketId: {}
    }

    const checkValue = (name: keyof typeof filters | string, value: string): string => name in filters && value in filters[name] ? filters[name][value] : value

    return Object.entries(searchParamsObject).map(([name, values], idx) => {
      if (values instanceof Array) {
        return values.map(value => <Chip sx={{ mr: 1 }} key={value} label={`${name}: ${checkValue(name, value)}`} onDelete={() => { handleDeleteFilter(name, value) }} />)
      } else {
        return <Chip sx={{ mr: 1 }} key={idx} label={`${name}: ${checkValue(name, values.toString())}`} onDelete={() => { handleDeleteFilter(name, values.toString()) }} />
      }
    })
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{data.application_id}: {data.application_fullName} / {data.application_email}</Typography>
      <Box my={2}>
        {renderFilters()}
      </Box>
      {renderNav()}
      {data.application_status !== StatusTypes.Deleted
        ? <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', border: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
          <StatusButtons
            receiptValidation={data.receiptValidation}
            id={data.application_id}
            status={data.application_status}
            updateHandler={updateStatusMutate}
          />
          <Button variant='contained' color='info' onClick={() => { navigate(`/applications/edit/${data.application_id}`) }} startIcon={<EditIcon />}>{t('common.edit')}</Button>
        </CardActions>
        : null}

      <Grid container spacing={2}>

        <Grid item xs={12} md={6} lg={4}>
          <DetailsList title={t('application.applicationStatus')}>
            {data.application_status in applicationStatuses
              ? <Item
                label={t('common.status')}
                text={<IconChip icon={applicationStatuses[data.application_status].icon} color={applicationStatuses[data.application_status].color} label={applicationStatuses[data.application_status].value ?? 'N/A'} />}
              />
              : null}

            {data.application_status === StatusTypes.EmailVerification
              ? <Item label={t('application.resendVerification')}
                text={
                  <Button
                    onClick={() => { resendVerification({ id: data.application_id.toString(), email: data.application_email }) }}
                    variant='contained'
                    disabled={isResending}
                    size="small"
                    sx={{ mt: 0.5 }}
                  >
                    {t('application.resendVerification')}
                  </Button>}
              />
              : null}
          </DetailsList>
          <DetailsList title={t('application.technicalApplicationData')}>
            <Item label={t('application.id')} text={data.application_id} />
            <Item label={t('common.createdAt')} text={data.application_createdAt} />
            <Item label={t('common.createdBy')} text={data.application_createdById === 1 ? t('application.system') : data.application_createdById} />
            {data.application_updatedById !== 1 ? <Item label={t('common.updatedAt')} text={data.application_updatedAt} /> : null}
            {data.application_updatedById !== 1 ? <Item label={t('common.updatedBy')} text={data.application_updatedById} /> : null}
          </DetailsList>
          {showHistory
            ? <History applicationId={params.id} marketCode={data.market_code} />
            : <Button variant='contained' onClick={() => { setShowHistory(true) }}>{t('application.applicationHistory')}</Button>}
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <DetailsList title={t('application.fullUserApplicationData')}>
            <Item label={t('application.fullName')} text={data.application_fullName} />
            <Item label={t('application.email')} text={data.application_email} />
            <Item label={t('application.phone')} text={data.application_phone} />
            <Item label={t('application.barcode')} text={data.barcode_global_code || data.application_code || 'N/A'} />
            <Item label={t('application.answer')} text={data.application_answer} />
          </DetailsList>
        </Grid>

      </Grid>

      {renderNav()}
    </>
  )
}

export default ApplicationDetails
