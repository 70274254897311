import React, { type ReactNode } from 'react'
import TableCell from '@mui/material/TableCell'

interface Field {
  label: string
  value: ReactNode
  breakWord?: boolean
}

export const renderCells = (fields: Field[]): ReactNode => fields.map(({ label, value, breakWord }) => <TableCell sx={{ width: 'auto', wordBreak: breakWord ? 'break-word' : 'initial' }} key={label} data-label={label}>{value}</TableCell>)
