import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { excelExt } from 'config/extensions'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import type { GlobalBarcodesFormValues } from './GlobalBarcodesForm.types'
import { useNavigate } from 'react-router-dom'
import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import FileUpload from 'components/FileUpload'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import Input from 'components/Input'

const GlobalBarcodesForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const { token, refreshToken } = useAuthContext()

  const validationSchema = Yup.object().shape({
  })

  const defaultValues: GlobalBarcodesFormValues = {
    file: null,
    backup: ''
  }

  const { mutate, data, status, reset } = useMutation(async (codes: FormData) => await axiosPost<any>(token, refreshToken, 'barcodes/import', codes))

  const { handleSubmit, control } = useForm<GlobalBarcodesFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (data !== undefined && 'error' in data) {
      changeMessage(data.error.data?.message, 'error', reset)
    }
    if (data !== undefined && status === 'success' && !('error' in data)) {
      changeMessage(t('forms.importSucess'), 'success', reset)
    }
  }, [data, status])

  const onHandleSubmit = (data: GlobalBarcodesFormValues): void => {
    const fd = new FormData()

    if (data.file !== null) {
      fd.append('file', data.file)
    }
    if (data.backup !== '') {
      fd.append('backup', data.backup)
    }

    mutate(fd)
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('common.addField', { field: t('navigation.globalBarcodes') })}</Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/barcodes') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
              <FileUpload
                control={control}
                extensions={excelExt}
                type='file'
                name='file'
                label={t('forms.fileContains', { field: t('navigation.globalBarcodes') })}
              />
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Button
                    type='submit'
                    color='success'
                    variant='contained'
                  >
                    {t('common.add')}
                  </Button>
                </Box>
              </Box>
              <Box>
                <Input
                  control={control}
                  label='backup'
                  name='backup'
                  type='switch'
                  textInputProps={{ size: 'small', sx: { mx: 0 } }}
                />
              </Box>
            </form>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/barcodes') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default GlobalBarcodesForm
