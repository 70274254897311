import React, { useMemo } from 'react'
import Button from '@mui/material/Button'
import Input from 'components/Input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { type HandleSubmitProps, type ApplicationFormFieldsProps } from './ApplicationForm.types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

const ApplicationFormFields = ({ isAddForm, onHandleSubmit, defaultValue, loadedFiles }: ApplicationFormFieldsProps): JSX.Element => {
  const { t } = useTranslation()

  const defaultValues: HandleSubmitProps = {
    ...defaultValue,
    email: defaultValue?.email ?? '',
    phone: defaultValue?.phone ?? '',
    fullName: defaultValue?.fullName ?? '',
    code: defaultValue?.code ?? '',
    answer: defaultValue?.answer ?? '',
    consent1: defaultValue?.consent1 ?? '',
    consent2: defaultValue?.consent2 ?? '',
    consent3: defaultValue?.consent3 ?? '',
    consent4: defaultValue?.consent4 ?? '',
    consent5: defaultValue?.consent5 ?? '',
    consent6: defaultValue?.consent6 ?? ''
  }

  const validationSchema = useMemo(() => {
    const defaultValidation: Record<keyof HandleSubmitProps, any> = {
      fullName: yup.string().required(t('common.required') ?? 'Required'),
      phone: yup.string().required(t('common.required') ?? 'Required'),
      email: yup
        .string()
        .email(t('forms.invalid', { field: t('application.email') }) ?? 'Bad Format')
        .required(t('common.required') ?? 'Required'),
      code: yup.string().required(),
      answer: yup.string(),
      consent1: yup.string().required(),
      consent2: yup.string().required(),
      consent3: yup.string().required(),
      consent4: yup.string(),
      consent5: yup.string(),
      consent6: yup.string()
    }

    return yup.object().shape(defaultValidation)
  }, [])

  const { handleSubmit, control } = useForm<HandleSubmitProps>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='submit'
          color='success'
          variant='contained'
          sx={{ mb: 2 }}
          startIcon={<DoneOutlinedIcon />}
        >
          {t(isAddForm ? 'common.add' : 'common.save')}
        </Button>
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Input<HandleSubmitProps> name='fullName' label={`${t('application.fullName')} *`} control={control} />
      <Input<HandleSubmitProps> name='email' label={t('application.email')} control={control} />
      <Input<HandleSubmitProps> name='phone' label={t('application.phone')} control={control} />
      <Input<HandleSubmitProps> name='code' label={t('application.code')} control={control} />
      <Input<HandleSubmitProps> name='answer' label={t('application.answer')} control={control} />
      <Divider />
      <Input<HandleSubmitProps> name='consent1' label={`${t('application.consent')} 1 *`} control={control} />
      <Input<HandleSubmitProps> name='consent2' label={`${t('application.consent')} 2 *`} control={control} />
      <Input<HandleSubmitProps> name='consent3' label={`${t('application.consent')} 3 *`} control={control} />
      <Input<HandleSubmitProps> name='consent4' label={`${t('application.consent')} 4`} control={control} />
      <Input<HandleSubmitProps> name='consent5' label={`${t('application.consent')} 5`} control={control} />
      <Input<HandleSubmitProps> name='consent6' label={`${t('application.consent')} 6`} control={control} />
      <Divider />
      <Box>
        {loadedFiles !== undefined
          ? loadedFiles.map(({ file }) => (
            <a
              key={file.id}
              href={`${process.env.REACT_APP_API_URL}/${file.path.replace('files\\', '') ?? ''}${file.name}.${file.extension}`}
              rel="noreferrer"
              target='_blank'
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/${file.path.replace('files\\', '') ?? ''}${file.name}.${file.extension}`}
                alt=''
                style={{ objectFit: 'contain', objectPosition: 'center' }}
                height={100}
                width={100}
              />
            </a>
          ))
          : null}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='submit'
          color='success'
          variant='contained'
          sx={{ mt: 2 }}
          startIcon={isAddForm ? <AddOutlinedIcon /> : <DoneOutlinedIcon />}
        >
          {t(isAddForm ? 'common.add' : 'common.save')}
        </Button>
      </Box>
    </form>
  )
}

export default ApplicationFormFields
