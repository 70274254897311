import React, { useEffect } from 'react'
import defineAbilityFor, { AbilityContext } from 'config/ability'
import { useAbility } from '@casl/react'
import { userDataName } from 'config/globalVariables'

const withAbilities = (Component: React.ComponentType): React.ComponentType => {
  const NewComponent = (): React.ReactElement => {
    const ability = useAbility(AbilityContext)

    useEffect(() => {
      const userStringifiedData = localStorage.getItem(userDataName)

      if (userStringifiedData !== null) {
        const userData = JSON.parse(userStringifiedData)
        const newrules = defineAbilityFor(userData).rules
        ability.update(newrules)
      }
    }, [])

    return <Component />
  }

  return NewComponent
}

export default withAbilities
