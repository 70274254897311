import React from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import RequestStatus from 'components/RequestStatus/RequestStatus'
import RangeChart from './RangeChart'
import PieChart from './PieChart'
import type { DashboardData } from './Dashboard.types'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import Box from '@mui/system/Box'
import useQueryGet from 'hooks/useQueryGet'

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation()

  const { data, isLoading } = useQueryGet<DashboardData>({
    queryKey: ['dashboard-applications'],
    endpoint: 'applications/dashboard'
  })

  if (data === null || data === undefined || 'error' in data) {
    return <RequestStatus data={data} isLoading={isLoading} />
  }

  return (
    <Grid container spacing={2}>

      <Grid item
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.down('lg')]: {
            spacing: 2
          },
          [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateAreas: '' +
              "'totalApplications applicationsLast7Days uniqueApplications'" +
              "'applicationsRange applicationsRange allApplicationsStatuses'" +
              "'applicationsRange applicationsRange allApplicationsStatuses'",
            gap: 2
          }
        })}
      >
        <Paper sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          p: 4,
          [theme.breakpoints.down('lg')]: {
            mb: 2
          },
          [theme.breakpoints.up('lg')]: {
            gridArea: 'totalApplications'
          }
        })}>
          <Avatar variant="rounded" sx={theme => ({ marginBottom: 1, background: theme.palette.primary.main, marginRight: 2 })}>
            <BarChartOutlinedIcon />
          </Avatar>
          <Box>
            <Typography variant='h5' fontWeight='700' component='h2'>
              {t('dashboard.totalApplications')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('dashboard.totalApplicationsDescription')}</Typography>
            <Typography sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
              {data.total}
            </Typography>
          </Box>
        </Paper>

        <Paper sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          p: 4,
          [theme.breakpoints.down('lg')]: {
            mb: 2
          },
          [theme.breakpoints.up('lg')]: {
            gridArea: 'applicationsLast7Days'
          }
        })}>
          <Avatar variant="rounded" sx={theme => ({ marginBottom: 1, background: theme.palette.primary.main, marginRight: 2 })}>
            < CalendarMonthOutlinedIcon />
          </Avatar>
          <Box>
            <Typography variant='h5' fontWeight='700' component='h2'>
              {t('dashboard.applicationsLast7Days')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('dashboard.applicationsLast7DaysDescription')}</Typography>
            <Typography sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>{data.fromLast7Days.length}</Typography>
          </Box>
        </Paper>

        <Paper sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          p: 4,
          [theme.breakpoints.down('lg')]: {
            mb: 2
          },
          [theme.breakpoints.up('lg')]: {
            gridArea: 'uniqueApplications'
          }
        })} >
          <Avatar variant="rounded" sx={theme => ({ marginBottom: 1, background: theme.palette.primary.main, marginRight: 2 })}>
            <QueryStatsOutlinedIcon />
          </Avatar>
          <Box>
            <Typography variant='h5' fontWeight='700' component='h2'>
              {t('dashboard.uniqueApplications')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('dashboard.uniqueApplicationsDescription')}</Typography>
            <Typography sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>{data.unique}</Typography>
          </Box>
        </Paper>

        <Paper sx={(theme) => ({
          p: 4,
          [theme.breakpoints.down('lg')]: {
            mb: 2
          },
          [theme.breakpoints.up('lg')]: {
            gridArea: 'applicationsRange'
          }
        })}>
          <Typography variant='h5' fontWeight='700' component='h2' mb={2}>
            {t('dashboard.applicationsRange')}
          </Typography>
          <RangeChart applications={data.fromLast7Days} />
        </Paper>

        <Paper sx={(theme) => ({
          p: 4,
          [theme.breakpoints.down('lg')]: {
            mb: 2
          },
          [theme.breakpoints.up('lg')]: {
            gridArea: 'allApplicationsStatuses'
          }
        })}>
          <Typography variant='h5' fontWeight='700' component='h2' mb={2}>
            {t('dashboard.allApplicationsStatuses')}
          </Typography>
          <PieChart applications={data.statuses} />
        </Paper>
      </Grid>

    </Grid >
  )
}

export default Dashboard
