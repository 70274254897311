import React from 'react'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'

interface SignInCodeStepProps {
  onHandleSubmit: () => Promise<unknown>
  codeString: string
}

const SignInCodeStep = ({ onHandleSubmit, codeString }: SignInCodeStepProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography component='h1' variant='h5' textAlign='center'>
        {t('signIn.scanQR')}
      </Typography>
      <img src={codeString} alt='QR code to activate 2fa authorization' />
      <Button
        type='button'
        fullWidth
        size="large"
        variant='contained'
        sx={{ mt: 3, mb: 2 }}
        onClick={onHandleSubmit}
        startIcon={<DoneOutlinedIcon />}
      >
        {t('common.save')}
      </Button>
    </Box>
  )
}

export default SignInCodeStep
