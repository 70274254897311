
import React, { useEffect, useState } from 'react'
import type { FileUploadContentProps } from './FileUpload.types'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

const FileContent = ({ value, type = 'image' }: FileUploadContentProps): JSX.Element => {
  const apiUrl = process.env.REACT_APP_API_URL
  const [selectedFile, setSelectedFile] = useState<string | undefined>()

  useEffect(() => {
    if (value instanceof File) {
      const reader = new FileReader()
      reader.readAsDataURL(value)
      reader.onloadend = (e) => { setSelectedFile(reader.result as string) }
    } else if ('path' in value && 'name' in value && 'extension' in value) {
      const pathToFile = `${apiUrl}/${value.path ?? ''}/${value.name ?? ''}.${value.extension ?? ''}`
      setSelectedFile(pathToFile)
    }
  }, [value])

  return type === 'image'
    ? <img
      width='300px'
      height='200px'
      style={{ objectFit: 'contain' }}
      src={selectedFile} />
    : <>
      <DescriptionOutlinedIcon fontSize='large' /><br />
      <a href={selectedFile} style={{ zIndex: 5, position: 'relative' }} target='_blank' rel="noreferrer noopener">{value.name}</a>
    </>
}

export default FileContent
