import { Autocomplete, FormControl, FormHelperText, InputLabel, TextField } from '@mui/material'
import MuiSelect from '@mui/material/Select'
import React from 'react'
import { Controller, useController } from 'react-hook-form'
import type { CustomSelectProps } from './Select.types'
import type { FieldValues } from 'react-hook-form'

const Select: <T, R extends FieldValues>(props: CustomSelectProps<T, R>) => JSX.Element = ({
  control,
  name,
  label,
  elements,
  multiple = false,
  withText,
  renderFunction,
  renderOption,
  getOptionLabel,
  withTextProps,
  formControlProps,
  helperText,
  ...restSelectProps
}) => {
  const { formState: { errors } } = useController({ name, control })
  if (withText === true) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...withTextProps}
            // @ts-expect-error-next-line
            renderOption={renderOption}
            value={field.value}
            onChange={(_, data) => {
              field.onChange(data)
              return data
            }}
            id={`${name}-select`}
            options={elements}
            multiple={multiple}
            fullWidth
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={Boolean(errors?.[name]?.message)}
                helperText={<>{errors?.[name]?.message}</>}
              />
            )}
          />
        )}
      />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth error={Boolean(errors?.[name]?.message)} {...formControlProps}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <MuiSelect
            {...{ ...field, ...restSelectProps }}
            labelId={`${name}-label`}
            multiple={multiple}
            id={`${name}-label`}
            label={label}
            name={name}
            MenuProps={{ sx: { maxHeight: 285 }, ...restSelectProps.MenuProps }}
          >
            {renderFunction !== undefined ? elements.map(renderFunction) : null}
          </MuiSelect>
          {helperText !== undefined ? <FormHelperText>{helperText}</FormHelperText> : null}
          {errors?.[name]?.message !== undefined ? <FormHelperText><>{errors?.[name]?.message}</></FormHelperText> : null}
        </FormControl>
      )}
    />
  )
}

export default Select
