import React, { useState } from 'react'
import type { TooltipMenuProps } from './TooltipMenu.types'
// import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
// import Tooltip from '@mui/material/Tooltip'

const TooltipMenu = ({ children, icon, name, anchorOrigin, anchorPosition, transformOrigin }: TooltipMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? name : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {icon !== undefined ? icon : <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id={name}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: anchorPosition === 'right' ? 14 : 'auto',
              left: anchorPosition === 'left' ? 14 : 'auto',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={transformOrigin ?? { horizontal: 'right', vertical: 'top' }}
        anchorOrigin={anchorOrigin ?? { horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </Menu>
    </>
  )
}

export default TooltipMenu
