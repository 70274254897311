import React from 'react'
import { type IconChipProps } from './IconChip.types'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

const IconAvatar = styled(Avatar)({
  '& .MuiSvgIcon-root': {
    width: 18,
    color: '#fff'
  }
})

const IconChip = ({ icon, color, label }: IconChipProps): JSX.Element => (
    <Chip
      sx={{ background: 'none', fontSize: 'inherit' }}
      avatar={<IconAvatar sx={{ bgcolor: color }}>{icon}</IconAvatar>}
      label={label}
    />
)

export default IconChip
