import { type UseQueryResult, useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { type AxiosResponse, type AxiosRequestConfig } from 'axios'
import { axiosGet } from 'connectors/axiosGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { useEffect } from 'react'

interface UseQueryGet<T> {
  queryKey: any[]
  endpoint: string
  options?: AxiosRequestConfig<any>
  queryOptions?: Omit<UseQueryOptions<T | { error: AxiosResponse | undefined } | undefined>, 'queryKey' | 'queryFn'>
  refetchDeps?: any[]
}

const useQueryGet = <T>({
  queryKey,
  refetchDeps = [],
  endpoint,
  options,
  queryOptions
}: UseQueryGet<T>): UseQueryResult<T | { error: AxiosResponse | undefined } | undefined> => {
  const { refreshToken } = useAuthContext()

  const result = useQuery(
    queryKey,
    async () => (await axiosGet<T | { error: AxiosResponse | undefined }>(endpoint, refreshToken, options)),
    // @ts-expect-error-next-line
    {
      ...queryOptions,
      cacheTime: queryOptions?.cacheTime ?? 0,
      refetchInterval: false,
      refetchOnWindowFocus: false
    })

  useEffect(() => {
    void result.refetch({ queryKey })
  }, refetchDeps)

  useEffect(() => {
    if (result.data !== undefined && result.data !== null && result.data instanceof Object && 'error' in result.data && result.data.error?.data.code === 'refreshed') {
      void result.refetch()
    }
  }, [result])

  return result
}

export default useQueryGet
