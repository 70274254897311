import React from 'react'
import type { BarcodesFormValues } from './BarcodesForm.types'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { excelExt } from 'config/extensions'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import { useNavigate } from 'react-router-dom'
import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import FileUpload from 'components/FileUpload'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const BarcodesForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token, refreshToken } = useAuthContext()

  const validationSchema = Yup.object().shape({
  })

  const defaultValues: BarcodesFormValues = {
    market: null,
    file: null
  }

  const { mutate } = useMutation(async (codes: FormData) => await axiosPost<any>(token, refreshToken, 'barcodes/import', codes))

  const { handleSubmit, control } = useForm<BarcodesFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const onHandleSubmit = (data: BarcodesFormValues): void => {
    const fd = new FormData()

    if (data.file !== null) {
      fd.append('file', data.file)
    }

    if (data.market !== null) {
      fd.append('marketId', data.market.id.toString())
    }

    mutate(fd)
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>
        {t('common.addField', { field: t('navigation.barcodes') })}
      </Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/barcodes') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>}/>
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
              <FileUpload<BarcodesFormValues>
                control={control}
                extensions={excelExt}
                type='file'
                name='file'
                label={t('forms.fileContains', { field: t('navigation.barcodes') })}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  type='button'
                  variant='outlined'
                  onClick={() => { navigate('/barcodes') }}
                  sx={{ mr: 1 }}
                >
                  {t('common.backListText')}
                </Button>
                <Button
                  type='submit'
                  color='success'
                  variant='contained'
                >
                  {t('common.add')}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/barcodes') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default BarcodesForm
