import { Button, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import Box from '@mui/material/Box'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import RequestStatus from 'components/RequestStatus'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { ApiApplicationDetails, APIApplication, ApiApplicationAdditionalInfo } from 'types/Application'
import type { HandleSubmitProps } from './ApplicationForm.types'
import ApplicationFormFields from './ApplicationFormFields'
import { useAppContext } from 'context/AppContext/AppContext'
import { axiosPatch } from 'connectors/axiosPatch'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const ApplicationEditForm = (): JSX.Element => {
  const { user } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const params = useParams()
  const queryClient = useQueryClient()
  const { changeMessage } = useAlert()

  const { data: applicationData, isLoading: applicationLoading } = useQueryGet<ApiApplicationDetails>({
    queryKey: ['application-details', params.id],
    endpoint: `applications/${params.id ?? 1}`,
    queryOptions: { enabled: params.id !== undefined }
  })

  const { mutate: editApplication, data: editData, reset: resetEdit } = useMutation(
    async (marketData: FormData) => await axiosPatch<APIApplication>(token, refreshToken, `applications/${params.id ?? ''}`, marketData),
    {
      onSuccess: (_, variables) => {
        if (applicationData !== undefined && !('error' in applicationData)) {
          const newValues = Object.fromEntries(Object.entries(applicationData).map(([key, value]) => [key, variables.get(key) ?? value]))

          changeMessage(t('common.success'), 'success', () => {
            queryClient.setQueryData(
              ['application-details', params.id],
              { ...newValues }
            )
            navigate('/applications')
          })
        }
      },
      onError: (err) => {
        console.error(err)
      }
    }
  )

  useEffect(() => {
    if (editData !== undefined && 'error' in editData) {
      changeMessage(editData?.error?.data?.message, 'error', resetEdit)
    }
  }, [editData])

  const extraApplicationInfo = useQueryGet<ApiApplicationAdditionalInfo>({
    queryKey: ['application-extra-info', params.id],
    endpoint: `applications/additional-data/${params.id ?? ''}`
  })

  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    if (applicationData === undefined || (applicationData !== undefined && 'error' in applicationData)) {
      return
    }

    const { ...restData } = data
    const fd = new FormData()

    const body = {
      ...restData,
      updatedById: user?.id.toString() ?? ''
    }
    Object.entries(body).forEach(([key, value]) => {
      fd.append(key, value)
    })
    editApplication(fd)
  }

  if (applicationData === undefined || 'error' in applicationData) {
    return <RequestStatus data={applicationData} isLoading={applicationLoading} />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>
        {t('common.editField', { field: t('navigation.applications') })}
      </Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/applications') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            <ApplicationFormFields
              isAddForm={false}
              state={state}
              onHandleSubmit={onHandleSubmit}
              loadedFiles={extraApplicationInfo.data !== undefined && !('error' in extraApplicationInfo.data) ? extraApplicationInfo.data?.files : []}
              defaultValue={{
                fullName: applicationData.application_fullName !== 'undefined' ? applicationData.application_fullName : '',
                email: applicationData.application_email !== 'undefined' ? applicationData.application_email : '',
                phone: applicationData.application_fullName !== 'undefined' ? applicationData.application_phone : '',
                code: applicationData?.application_code ?? '',
                answer: applicationData?.application_answer ?? '',
                consent1: applicationData?.application_consent1 ?? '',
                consent2: applicationData?.application_consent2 ?? '',
                consent3: applicationData?.application_consent3 ?? '',
                consent4: applicationData?.application_consent4 ?? '',
                consent5: applicationData?.application_consent5 ?? '',
                consent6: applicationData?.application_consent6 ?? ''
              }}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/applications') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default ApplicationEditForm
