import React, { useState, useEffect } from 'react'
import { Paper, Typography } from '@mui/material'
import { userDataName } from 'config/globalVariables'
import type { User } from 'types/User'
import { useTranslation } from 'react-i18next'
import ErrorView from 'components/ErrorView'

const Account = (): JSX.Element => {
  const { t } = useTranslation()
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const data = localStorage.getItem(userDataName)

    if (data !== undefined && data !== null) {
      setUser(JSON.parse(data))
    }
  }, [])

  if (user === null) {
    return <ErrorView type='privilages' />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('navigation.account')}</Typography>
      <Paper sx={{ p: 4 }}>
        <Typography fontSize='20px'>
          {t('user.name')}: {user.name}
        </Typography>
        {/* <Link to='/change-account-data/name'>{t('common.change')}</Link> */}
        <Typography fontSize='20px'>
          {t('user.email')}: {user.email}
        </Typography>
        {/* <Link to='/change-account-data/email'>{t('common.change')}</Link> */}
        <Typography fontSize='20px'>
          {t('user.password')}: ********
        </Typography>
        {/* <Link to='/change-account-data/password'>{t('common.change')}</Link> */}
      </Paper>
    </>
  )
}

export default Account
