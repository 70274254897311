import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AdvancedTable from 'components/AdvancedTable'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import type { PrizeGroup } from 'types/Prize'
import { useNavigate } from 'react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ActionDropdown from 'components/ActionDropdown'
import { axiosDelete } from 'connectors/axiosDelete'
import { renderDate } from 'utils/renderDate'
import { Can } from 'config/ability'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Link from '@mui/material/Link'
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import IconChip from 'components/IconChip'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckIcon from '@mui/icons-material/Check'
import type Avatar from '@mui/material/Avatar'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { renderCells } from 'utils/renderCells'
import { generateQueryParams } from 'utils/generateQueryParams'
import type { Filters } from 'context/FiltersContext/FiltersContext.types'
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined'

const PrizesGroups = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { changeMessage } = useAlert()
  const navigate = useNavigate()
  const { t } = useTranslation()
  // const { markets } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const [isExportMode, setIsExportMode] = useState<boolean>(false)

  const handleDownloadPrizeGroups = async (filterToDownload: Filters): Promise<any> => {
    const params = generateQueryParams(filterToDownload)

    const url = `${process.env.REACT_APP_API_URL}/prizes/groups/export?${params.toString()}`

    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const res = await fetch(url, options)
    const blob = await res.blob()
    const file = window.URL.createObjectURL(blob)

    window.location.assign(file)
    setIsExportMode(false)
    void queryClient.refetchQueries(['markets-query'])
  }

  const percent = (left: number, total: number): { percent: string, color: React.ComponentProps<typeof Avatar>['color'], icon: JSX.Element } => {
    const value = Math.floor(left / total * 100)
    if (value <= 10) {
      return ({
        percent: `${value} %`,
        color: 'error.main',
        icon: <CloseOutlinedIcon />
      })
    } else if (value <= 30) {
      return ({
        percent: `${value} %`,
        color: 'warning.main',
        icon: <PriorityHighIcon />
      })
    } else if (value <= 50) {
      return ({
        percent: `${value} %`,
        color: 'warning.main',
        icon: <PriorityHighIcon />
      })
    }
    return ({
      percent: `${value} %`,
      color: 'success.main',
      icon: <CheckIcon />
    })
  }

  const { mutate: deleteMutate, data: deleteResponse, reset } = useMutation(async (id: number) => await axiosDelete(token, refreshToken, `prizes/groups/${id}`), {
    onSuccess: (_, id) => {
      changeMessage(t('common.success'), 'success', reset)
      void queryClient.refetchQueries(['prizes-groups-query'])
    }
  })

  useEffect(() => {
    if (deleteResponse !== undefined && 'error' in deleteResponse) {
      changeMessage(deleteResponse?.error?.data?.message, 'error', reset)
    }
  }, [deleteResponse])

  const changeExportMode = (value: boolean): void => {
    setIsExportMode(value)
  }

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('prize.groups')}</Typography>
        <Button variant='contained' sx={{ ml: 'auto', mr: 1 }} onClick={(): void => { navigate('/prizes') }} startIcon={<ViewTimelineOutlinedIcon />}>{t('prize.showAll')}</Button>
        <Can I='download' a='Prizes'>
          <Button
            onClick={() => { setIsExportMode(true) }}
            type='button'
            variant='contained'
            color='info'
            sx={{ mr: 1 }}
            startIcon={<VerticalAlignBottomOutlinedIcon />}
          >
            {t('common.export')}
          </Button>
        </Can>
        <Can I='create' a='Prizes'>
          <Button variant='contained' onClick={(): void => { navigate('/prizes/add') }} startIcon={<AddOutlinedIcon />}>{t('common.add')}</Button>
        </Can>
      </Box>
      <AdvancedTable<PrizeGroup>
        name='prizes-groups'
        endpoint='prizes/groups'
        isExportMode={isExportMode}
        changeExportMode={changeExportMode}
        headings={[
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'empty', name: t('common.name'), field: 'name' },
          { type: 'empty', name: t('common.createdAt'), field: 'createdAt', width: 160 },
          { type: 'empty', name: t('common.availability'), field: '', width: 120 },
          { type: 'empty', name: t('common.type'), field: '', width: 120 },
          { type: 'empty', name: t('common.actions'), field: '', width: 120 }
        ]}
        options={{}}
        onUsedFilters={async (filters) => {
          if (isExportMode) {
            await handleDownloadPrizeGroups({ ...filters })
          }
        }}
        renderRow={(row, idx) => (
          <TableRow key={idx}>
            {renderCells([
              { label: 'ID', value: row.id },
              { label: t('common.name'), value: <Link href={`/prizes/edit/${row.id}`}>{row.name}</Link> },
              { label: t('common.createdAt'), value: renderDate(row.createdAt) },
              { label: t('common.availability'), value: <IconChip icon={percent(row.amountLeft, row.totalAmount).icon} color={percent(row.amountLeft, row.totalAmount).color} label={percent(row.amountLeft, row.totalAmount).percent} /> },
              { label: t('common.type'), value: row.type }
            ])}
            <ActionDropdown
              subject='Prizes'
              name={row.name}
              buttons={[
                {
                  label: t('common.edit'),
                  name: 'edit-prize-group',
                  handler: (): void => { navigate(`/prizes/edit/${row.id}`, { state: { ...row } }) },
                  icon: <EditOutlinedIcon color='info' />
                },
                {
                  label: t('common.delete'),
                  name: 'remove-prize-group',
                  handler: (): void => { deleteMutate(row.id) },
                  icon: <DeleteOutlineOutlinedIcon color='error' />
                }
              ]}
            />
          </TableRow>
        )}
      />
    </>
  )
}

export default PrizesGroups
