import React, { type ReactElement } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import i18next from 'i18next'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import { routerOptions } from 'config/router'
import withAbilities from 'HOC/withAbilities'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import AppProvider from 'context/AppContext/AppContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import AlertProvider from 'context/AlertContext'
import AuthProvider from 'context/AuthContext/AuthContext'
import { Helmet } from 'react-helmet'
const queryClient = new QueryClient()
const router = createBrowserRouter(routerOptions)

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'en',
    fallbackLng: ['pl', 'en']
  })
const App = (): ReactElement => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <AuthProvider>
          <AppProvider>
            <Helmet defaultTitle="Plej CMS">
              {!(process.env.REACT_APP_ENVIRONMENT === 'PROD') ? <meta name="robots" content="noindex, nofollow" /> : null}
            </Helmet>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </AppProvider>
        </AuthProvider>
      </AlertProvider>
    </QueryClientProvider>
  </LocalizationProvider>
)

export default withAbilities(App)
