import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Input from 'components/Input'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'

interface HandleSubmitProps {
  password: string
  repeatPassword: string
}

const defaultValues: HandleSubmitProps = {
  password: '',
  repeatPassword: ''
}

const ForgotPasswordStep2 = ({ resetToken }: { resetToken: string }): JSX.Element => {
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const { t } = useTranslation()
  const { token, refreshToken } = useAuthContext()
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('forms.required', { field: t('signIn.password') }) ?? 'Required')
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{16,})/,
        t('forms.passwordValidation') ?? 'Bad Validation'
      ),
    repeatPassword: Yup.string()
      .required(t('forms.required', { field: t('signIn.password') }) ?? 'Required')
      .test({
        message: t('forgotPassword.passwordsIncorrect', { field: t('signIn.password') }) ?? 'Passwords must match',
        test: (value, { parent }) => {
          return 'password' in parent && parent.password === value
        }
      })
  })

  const handleReset = (): void => {
    reset()
    if (data !== undefined && typeof data === 'string') {
      navigate('/')
    }
  }

  const { mutate, isLoading, data, reset } = useMutation(async (newPassword: string) => await axiosPost<string>(token, refreshToken, 'users/reset/password', { newPassword, token: resetToken }))
  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    mutate(data.password)
  }

  useEffect(() => {
    if (data === '') {
      changeMessage(t('dialogs.passwordChanged'), 'success', handleReset)
    }
    if (data !== undefined && typeof data !== 'string' && 'error' in data) {
      changeMessage(data.error?.data.message, 'error', handleReset)
    }
  }, [data])

  const { handleSubmit, control } = useForm<HandleSubmitProps>({ defaultValues, resolver: yupResolver(validationSchema) })

  return (
    <>
      <Typography component='h1' variant='h5' align='center'>
        {t('forgotPassword.secondTitle')}
      </Typography>
      <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
        <Input<HandleSubmitProps>
          textInputProps={{ autoFocus: true, type: 'password' }}
          name='password'
          label={t('user.password')}
          control={control}
        />
        <Input<HandleSubmitProps>
          textInputProps={{ type: 'password' }}
          name='repeatPassword'
          label={t('user.repeatPassword')}
          control={control}
        />
        <Button
          type='submit'
          fullWidth
          size="large"
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress /> : t('forgotPassword.confirmPassword')}
        </Button>

        <Link component={RouterLink} to='/'>
          {t('common.backText')}
        </Link>
      </form>
    </>
  )
}

export default ForgotPasswordStep2
