import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import type { Filters, FiltersContextValue, FiltersProviderProps } from './FiltersContext.types'
import { useSearchParams } from 'react-router-dom'
import FiltersList from './FiltersList'
import { generateQueryParams, readQueryParams } from 'utils/generateQueryParams'
import { renderDate } from 'utils/renderDate'
// import { useTranslation } from 'react-i18next'

const defaultValue: FiltersContextValue = {
  filters: {},
  updateFilter: () => { },
  changeFilters: () => { },
  toggleDrawer: () => { },
  isOpen: false,
  options: {},
  headings: [],
  customFilters: [],
  isExportMode: false,
  exportText: ''
}

export const FiltersContext = createContext(defaultValue)

export const useFilters = (): FiltersContextValue => {
  const data = useContext(FiltersContext)

  return data
}

const FiltersProvider = ({ children, options, headings, isExportMode, exportText, disableFilters, onUsedFilters, changeExportMode, customFilters }: FiltersProviderProps): JSX.Element => {
  const loadedParams = useRef(false)
  const [isOpen, setIsOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<Filters>({})

  useEffect(() => {
    if (isExportMode) {
      setIsOpen(true)
    }
  }, [isExportMode])

  const updateFilter = (newFilters: Filters): void => {
    setFilters(prev => ({ ...prev, ...newFilters }))
    setIsOpen(false)
  }

  const changeFilters = (newFilters: Filters): void => {
    const newData = Object.fromEntries(Object.entries(newFilters).map(([key, value]) => [key, value instanceof Array ? value.map(e => e.trim()) : (value.toString()).trim()]))
    setFilters(prev => ({ take: prev.take, page: prev.page, order: prev.order, orderField: prev.orderField, ...newData }))
    setIsOpen(false)
  }

  useEffect(() => {
    if (loadedParams.current) {
      const { createdAtStart, createdAtFinish, ...restFilters } = filters
      const values = generateQueryParams(restFilters as Record<string, string | string[]>)
      setSearchParams(values)
      onUsedFilters?.({
        ...restFilters,
        createdDateStart: createdAtStart ? renderDate(createdAtStart.toString(), false, '-', true) : '',
        createdDateEnd: createdAtFinish ? renderDate(createdAtFinish.toString(), false, '-', true) : ''
      })
    }
  }, [filters])

  useEffect(() => {
    const data = readQueryParams(searchParams)
    changeFilters(data)
    loadedParams.current = true
  }, [])

  const toggleDrawer = (newValue?: boolean): void => {
    if (newValue !== undefined) {
      setIsOpen(newValue)
    } else {
      setIsOpen(p => !p)
    }
  }

  const filtersView = useMemo(() => !disableFilters ? <FiltersList /> : null, [filters])

  return (
    <FiltersContext.Provider value={{ filters, updateFilter, changeFilters, toggleDrawer, isOpen, options, customFilters, headings, isExportMode, exportText, changeExportMode }}>
      {filtersView}
      {children}
    </FiltersContext.Provider>
  )
}

export default FiltersProvider
