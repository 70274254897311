import { Button, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import Box from '@mui/material/Box'
import { useMutation } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import type { APIApplication } from 'types/Application'
import type { HandleSubmitProps } from './ApplicationForm.types'
import ApplicationFormFields from './ApplicationFormFields'
import { axiosPost } from 'connectors/axiosPost'
import { useAppContext } from 'context/AppContext/AppContext'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const ApplicationAddForm = (): JSX.Element => {
  const { user } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { changeMessage } = useAlert()

  const { mutate: addApplication, data: addData, reset: resetAdd } = useMutation(
    async (marketData: FormData) => await axiosPost<APIApplication>(token, refreshToken, 'applications/add', marketData))

  useEffect(() => {
    if (addData !== undefined && 'error' in addData) {
      changeMessage(addData?.error?.data?.message, 'error', resetAdd)
    }
    if (addData !== undefined && 'id' in addData) {
      changeMessage(t('common.success'), 'success', handleReset)
    }
  }, [addData])

  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    const { ...restData } = data
    const fd = new FormData()

    fd.append('recaptchaToken', 'xmofftcfhi')
    const body = {
      ...restData,
      updatedById: user?.id.toString() ?? ''
    }
    Object.entries(body).forEach(([key, value]) => {
      fd.append(key, value)
    })
    addApplication(fd)
  }

  const handleReset = (): void => {
    resetAdd()
    navigate('/applications')
  }

  return (
    <Card>
      <CardHeader title={<Button
        type='button'
        variant='outlined'
        onClick={() => { navigate('/applications') }}
        startIcon={<ChevronLeft />}
      >
        {t('common.backListText')}
      </Button>} />
      <CardContent>
        <Box sx={{ maxWidth: 640, width: '100%' }}>
          <ApplicationFormFields
            isAddForm
            state={state}
            onHandleSubmit={onHandleSubmit}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/applications') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default ApplicationAddForm
