import axios from 'axios'

export const activateAccount = async (code: string, token: string): Promise<any> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/2fa/turn-on`, {
    twoFactorAuthenticationCode: code
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}
