import React, { useState } from 'react'
import type { EditButtonProps } from './ActionDropdown.types'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from 'components/ConfirmDialog'
import { useTranslation } from 'react-i18next'
import { Can } from 'config/ability'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

const EditCell = styled(TableCell)({
  position: 'sticky',
  right: 0,
  top: 0
})

const EditButton = ({ name, buttons, deleteHandler, editUrl, field, subject }: EditButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const editHandler = (): void => {
    handleClose()
    if (editUrl !== undefined) {
      navigate(editUrl)
    }
  }

  const handleShowDialog = (): void => { setShowDialog(true) }
  const handleHideDialog = (): void => { setShowDialog(false) }

  const handleDelete = (): void => {
    handleClose()
    handleHideDialog()
    if (deleteHandler !== undefined) {
      deleteHandler()
    }
  }

  return (
    <EditCell data-label={t('common.actions')} align='center'>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ '@media screen and (max-width: 991px)': { display: 'none' } }}
      >
        <MoreVertIcon />
      </IconButton>
      <Box sx={{ width: 'max-content', '@media screen and (min-width: 992px)': { display: 'none' } }}>
        {editUrl !== undefined
          ? <Can I='update' a={subject}>
            <IconButton color='info' onClick={editHandler} title={t('common.edit') ?? ''}><EditOutlinedIcon /></IconButton>
          </Can>
          : null}
        {(deleteHandler !== undefined)
          ? <Can I='delete' a={subject}>
            <IconButton color='error' onClick={handleShowDialog} title={t('common.delete') ?? ''}><DeleteOutlineOutlinedIcon /></IconButton>
          </Can>
          : null}
        {buttons !== undefined
          ? buttons.map(el => el.disabled === undefined || (el.disabled !== undefined && !el.disabled)
            ? <IconButton key={el.name} onClick={el.handler} title={el.label}>{el.icon}</IconButton>
            : null)
          : null}
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {buttons !== undefined
          ? buttons.map(el => el.disabled === undefined || (el.disabled !== undefined && !el.disabled)
            ? <MenuItem key={el.name} onClick={el.handler} sx={{ display: 'flex', gap: 1 }}>{el.icon} {el.label}</MenuItem>
            : null)
          : null}
        {editUrl !== undefined ? <Can I='update' a={subject}><MenuItem onClick={editHandler}><EditOutlinedIcon sx={{ mr: 1 }} /> {t('common.edit')}</MenuItem></Can> : null}
        {(deleteHandler !== undefined) ? <Can I='delete' a={subject}><MenuItem onClick={handleShowDialog}><DeleteOutlineOutlinedIcon sx={{ mr: 1 }} /> {t('common.delete')}</MenuItem></Can> : null}
      </Menu>
      <ConfirmDialog
        open={showDialog}
        onAgree={handleDelete}
        onDisagree={handleHideDialog}
        question={t('dialogs.delete', { name, field: field ?? '' })}
        confirmText={t('common.delete')}
      />
    </EditCell>
  )
}

export default EditButton
