import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import type { AuthContextValue, AuthProviderProps } from './AuthContext.types'
import { accessTokenCookieName } from 'config/globalVariables'
import useCookie from 'hooks/useCookie'
import { refreshTokenFn } from 'authorization/refreshToken'
import ConfirmDialog from 'components/ConfirmDialog'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'context/AlertContext'

const defaultValue: AuthContextValue = {
  token: '',
  updateToken: () => { },
  refreshToken: async () => { },
  isLoading: false,
  changeLoader: () => { },
  get2fa: async (cb: (code: string) => Promise<void>) => { }
}

export const AuthContext = createContext(defaultValue)

export const useAuthContext = (): AuthContextValue => {
  const data = useContext(AuthContext)

  return data
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const { t } = useTranslation()
  const tfaRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [token, updateToken] = useCookie(accessTokenCookieName)
  const [tfa, setTfa] = useState('')
  const [isRequired2fa, setIsRequired2fa] = useState(false)
  const { changeMessage } = useAlert()
  const [callback, setCallback] = useState<((code: string) => void) | undefined>()

  const refreshToken = async (): Promise<any> => {
    setIsLoading(true)
    const result = await refreshTokenFn(updateToken)
    setIsLoading(false)
    return result
  }

  const changeLoader = (value: boolean): void => {
    setIsLoading(value)
  }

  const clear2fa = (): void => {
    setIsRequired2fa(false)
    setTfa('')
  }

  const get2fa = async (cb: (tfa: string) => void): Promise<void> => {
    setIsRequired2fa(true)
    setCallback(() => cb)
  }

  useEffect(() => {
    if (!isRequired2fa && tfa.length === 6 && callback !== undefined) {
      callback(tfa)
    }
  }, [isRequired2fa])

  const handleCode = (): void => {
    const tfaInput = tfaRef.current

    if (tfaInput !== null) {
      const tfa = tfaInput.value

      if (tfa.length === 6) {
        setTfa(tfa)
        setIsRequired2fa(false)
      } else {
        changeMessage(t('dialogs.passTfa'), 'error', () => { })
      }
    } else {
      setIsRequired2fa(false)
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    handleCode()
  }

  return (
    <AuthContext.Provider value={{ token, updateToken, refreshToken, isLoading, changeLoader, get2fa }}>
      {children}
      <ConfirmDialog
        onAgree={handleCode}
        onDisagree={clear2fa}
        open={isRequired2fa}
        agreeColor='success'
        disagreeColor='error'
        question={<>
          <h2>{t('dialogs.passTfa')}</h2>
          <form onSubmit={handleSubmit}>
            <TextField inputRef={tfaRef} />
          </form>
        </>}
      />
    </AuthContext.Provider>
  )
}

export default AuthProvider
