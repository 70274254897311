import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Input from 'components/Input'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'

interface HandleSubmitProps {
  email: string
}

const defaultValues = {
  email: ''
}

const ForgotPasswordStep1 = (): JSX.Element => {
  const { t } = useTranslation()
  const { changeMessage } = useAlert()
  const { token, refreshToken } = useAuthContext()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('forms.required', { field: t('signIn.email') }) ?? 'Required')
      .email(t('forms.invalid', { field: t('signIn.email') }) ?? 'Invalid')
  })

  const { mutate, isLoading, data, reset } = useMutation(async (email: string) => await axiosPost<string>(token, refreshToken, 'users/reset/password/trigger', { email }))
  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    mutate(data.email)
  }

  useEffect(() => {
    if (data === '') {
      changeMessage(t('dialogs.emailSent'), 'success', reset)
    }
  }, [data])

  const { handleSubmit, control } = useForm<HandleSubmitProps>({ defaultValues, resolver: yupResolver(validationSchema) })

  return (
    <>
      <Typography component='h1' variant='h5' align='center'>
        {t('forgotPassword.title')}
      </Typography>
      <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
        <Input<HandleSubmitProps>
          textInputProps={{ autoFocus: true }}
          name='email'
          label={t('forgotPassword.email')}
          control={control}
        />
        <Button
          type='submit'
          fullWidth
          size="large"
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress /> : t('forgotPassword.btnText')}
        </Button>

        <Link component={RouterLink} to='/'>
          {t('common.backText')}
        </Link>
      </form>
    </>
  )
}

export default ForgotPasswordStep1
